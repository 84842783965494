import { define } from '@owenscorning/pcb.alpha';
import { useState, useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;
  background-color: #FFF;
  border: 1px solid ${ () => UI.Theme.colors.greyTint1 };
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
  display: flex;
  gap: 6px;
  left: ${ ({ position }) => position?.x ? `${ position.x }px` : '50%' };
  padding: 4px;
  position: absolute;
  top: ${ ({ position }) => position?.y ? position.y - 48 : -40 }px;
  transform: translateX(-50%);
  z-index: 9999;

  & * {
    color: #000 !important;
    font-style: normal !important;
    font-weight: normal !important;
  }

  & > * {
    vertical-align: middle;
  }
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%) scaleX(0.682);
  width: 0;
  height: 0;
  border-left: ${ ({ size }) => size }px solid transparent;
  border-right: ${ ({ size }) => size }px solid transparent;
  border-top: ${ ({ size }) => size }px solid ${ () => UI.Theme.colors.greyTint1 };

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: ${ ({ size }) => size - 1 }px solid transparent;
    border-right: ${ ({ size }) => size - 1 }px solid transparent;
    border-top: ${ ({ size }) => size - 1 }px solid ${ () => UI.Theme.colors.white };
    position: absolute;
    top: -${ ({ size }) => size + 1 }px;
    left: -${ ({ size }) => size - 1 }px;
  }
`;

export default define`Flyout`('0.0.1')({
  prepare: () => ({ children, position={ x: 0, y: 0 }, ...props }) => {
    const [ offset, setOffset ] = useState({ x: 0, y: 0 });

    const ref = useRef();
    useEffect(() => setOffset(UI.Behavior.Clamp(ref)), []);

    return <Container
      { ...props }
      position={{ x: position.x + offset.x, y: position.y + offset.y }}
      ref={ ref }
    >
      <div css={ css` position: absolute; left: ${ -offset.x }px; width: 100%; height: 100%; pointer-events: none; ` } >
        <Triangle size={ 11 } />
      </div>
      { children }
    </Container>;
  }
});
