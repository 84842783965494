import { Component, Children, createRef, isValidElement, cloneElement } from 'react';
import { jsx, css } from '@emotion/react';
import _ from 'lodash';
import stripBoardProps from '../../props';

export default class Belt extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, offset: { x: 0, y: 0 } };

    this.ref = createRef();
    this.outerClick = this.outerClick.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() { document.addEventListener('mousedown', this.outerClick);}
  componentWillUnmount() { document.removeEventListener('mousedown', this.outerClick); }

  outerClick(event) { if (this.ref && !this.ref?.current?.contains(event.target)) this.close(); }

  open() {
    this.setState({ open: true }, () => {
      const offset = UI.Behavior.Clamp(this.ref);
      if (offset.x != this.state.offset.x || offset.y != this.state.offset.y) this.setState({ ...this.state, offset });
    });
  }

  close() { this.setState({ open: false }); }
  toggle() { this.state.open ? this.close() : this.open(); }

  render() {
    const { actions, reference, openCSS, children, styling, ...props } = this.props;
    const styles = _.castArray(styling);

    return (
      <span { ...stripBoardProps(props) } css={[ css` display: inline-block; position: relative; `, ...styles ]} >
        <span>{
          isValidElement(children[0])
            ? cloneElement(
              children[0],
              {
                onMouseDown: (e) => { e.preventDefault(); e.stopPropagation(); },
                onClick: this.toggle,
                css: openCSS && this.state.open
                  ? _.castArray(children[0].props.css).concat(openCSS)
                  : children[0].props.css
              }
            )
            : children[0]
        }</span>
        {
          ( this.state.open || this.props?.hides ) &&
          <div ref={ this.ref } onClick={ this.close } css={[
            css`
              position: absolute;
              display: ${ this.state.open ? 'block' : 'none' };
              top: 17px;
              right: -1px;
              min-width: 122px;
              border-radius: 5px;
              background-color: #FFF;
              box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.25);
              padding: 5px 0px;
              z-index: 1000;
              transform: translate(${ this.state.offset.x }px, ${ this.state.offset.y }px);
            `,
            ..._.castArray(this.props.beltCSS)
          ]} >
            {
              actions?.map((action, index) => (
                action && <UI.Belt.Action key={ index } onClick={ () => action?.onClick(reference) } >
                  <UI.Icon type={ action?.icon } /> { action?.label }
                </UI.Belt.Action>
              ))
            }
            {
              Children.map(children.slice(1), (child) => {
                return isValidElement(child)
                  ? cloneElement(child)
                  : child;
              })
            }
          </div>
        }
      </span>
    );
  }
}
